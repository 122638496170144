// @flow
import * as React from 'react';

import { DndContext } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { useReactiveVar } from '@apollo/client';
import Field from '../../../basic-components/field';
import DraggableProgramsItem from '../../../basic-components/image-gallery/draggable-programs-item';
import { gpsrConfigsVar } from '../../../utils/apollo-cache';
import { getOptions } from '../../../store/gpsr';

type Props = {
  label: React.Node,
  value: any,
  fieldRef?: React.Ref<any>,
  noGrid?: boolean,
  onChanged: (value: any) => void,
  disabled?: boolean,
};

export default function ProgramCheckboxField({
  label,
  value,
  fieldRef,
  noGrid,
  onChanged,
  disabled,
}: Props): React.Node {
  const pgs = getOptions(useReactiveVar(gpsrConfigsVar), 'pictograms');

  const refInput = React.useRef();
  if (!value) {
    return <div />;
  }
  const handleLabelClick: () => void = () => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  };

  const pictograms = pgs.map((v) => ({
    ...v,
    checked: value.find((o) => o === v.value) !== undefined,
  }));

  return (
    <Field
      innerRef={fieldRef}
      label={label}
      onLabelClick={handleLabelClick}
      noGrid={noGrid}
    >
      <DndContext
        onDragStart={() => {}}
        onDragEnd={() => {}}
        autoScroll={false}
      >
        <SortableContext items={[]}>
          <div>
            {pictograms.map((pictogram) => (
              <div style={{ float: 'left', marginRight: '20px' }}>
                <DraggableProgramsItem
                  innerRef={refInput}
                  style={{}}
                  url={pictogram.url}
                  description={pictogram.label}
                  id={pictogram.value}
                  dragged
                  checked={pictogram.checked}
                  disabled={value.length >= 2 && !pictogram.checked}
                  onChanged={() => {
                    if (disabled) {
                      return;
                    }
                    const checked = !pictogram.checked;
                    if (checked) {
                      if (value.length < 2) {
                        onChanged([...value, pictogram.value]);
                      }
                    } else {
                      onChanged([
                        ...value.filter((v) => v !== pictogram.value),
                      ]);
                    }
                  }}
                />
              </div>
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </Field>
  );
}
